import React, { createContext, useState } from "react";

export const DrawerMobileContext = createContext();
const { Provider } = DrawerMobileContext;

export const DrawerMobileProvider = (props) => {
  const [drawerMobile, setDrawerMobile] = useState(false);

  return (
    <Provider value={[drawerMobile, setDrawerMobile]}>
      {props.children}
    </Provider>
  );
};
