import {
  //@START HAPUS
  /* Box,
  Button, */
  //@END HAPUS
  Radio,
  TextField,
  Typography,
  createTheme,
  Box,
  Checkbox,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CurrencyFormat from "react-currency-format";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import idLocale from "date-fns/locale/id";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#203354",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: '#203354',
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#203354",
      },
      daySelected: {
        backgroundColor: "#203354",
      },
      dayDisabled: {
        color: "#203354",
      },
      current: {
        color: "#203354",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#203354",
      },
    },
  },
});

const CssTextFieldError = withStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "red",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
    },
    "& label.Mui-focused": {
      color: "red !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "red",
      },
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
})(TextField);

// --Plain TextField--
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#203354 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#203354",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#D2D2D2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#99ADCF",
      },
      "&:hover fieldset": {
        borderColor: "#203354",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#203354",
      },
    },
  },
})(TextField);

export const InputText = ({
  placeholder,
  label,
  helperText,
  className,
  type,
  onChange,
  onInput,
  value,
  defaultValue,
  required,
  variant,
  onMouseUp,
  error,
  margin = 8,
  width,
  startAdornment,
  endAdornment,
  inputProps,
  ...props
}) => (
  <>
    {error ? (
      <CssTextFieldError
        onMouseUp={onMouseUp}
        autoComplete="off"
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onInput={onInput}
        type={type}
        className={className}
        id="standard-error"
        label={label}
        style={{ margin: margin, width: width }}
        placeholder={placeholder}
        helperText={helperText}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment:
            startAdornment !== undefined ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : null,
          endAdornment:
            endAdornment !== undefined ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : null,
        }}
        variant={variant}
        required={required}
        {...props}
      />
    ) : (
      <CssTextField
        onMouseUp={onMouseUp}
        autoComplete="off"
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onInput={onInput}
        type={type}
        className={className}
        id="standard-error"
        label={label}
        style={{ margin: margin, width: width }}
        placeholder={placeholder}
        helperText={helperText}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment:
            startAdornment !== undefined ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : null,
          endAdornment:
            endAdornment !== undefined ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : null,
        }}
        variant={variant}
        required={required}
        {...props}
      />
    )}
  </>
);

// --TextField with bold label--
const CssTextFieldBold = withStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "#221F1F",
      fontWeight: "bold", // or black
    },
    "& label.Mui-focused": {
      color: "#203354 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#203354",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#D2D2D2",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#203354",
      },
    },
  },
})(TextField);

export const InputTextBold = ({
  placeholder,
  label,
  helperText,
  className,
  type,
  onChange,
  value,
  required,
  onMouseUp,
  multiline,
  name,
  error,
  variant,
  disabled,
  autocomplete,
  ref,
  endAdornment,
  onBlur,
  defaultValue,
  id,
  widthLabel,
  step,
  min,
  max,
  width,
  size,
  styles,
  describedby,
  margin = 8,
}) => (
  <>
    {error ? (
      <CssTextFieldError
        size={size}
        minLength="8"
        ref={ref}
        onMouseUp={onMouseUp}
        autoComplete="off"
        value={value}
        onChange={onChange}
        type={type}
        className={className}
        id={id}
        label={label}
        style={{ margin: margin, width: width }}
        placeholder={placeholder}
        helperText={helperText}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: endAdornment,
          inputProps: {
            min: min,
            step: step,
            max: max,
            // autocomplete: autocomplete
          },
          style: styles,
        }}
        InputLabelProps={{
          shrink: true,
          style: { width: widthLabel },
        }}
        variant={variant}
        required={required}
        disabled={disabled}
        multiline={multiline}
        onBlur={onBlur}
        defaultValue={defaultValue}
        min="0"
        aria-describedby={describedby}
        // defaultValue={defaultValue}
      />
    ) : (
      <CssTextFieldBold
        size={size}
        ref={ref}
        onBlur={onBlur}
        defaultValue={defaultValue}
        InputProps={{
          endAdornment: endAdornment,
          inputProps: {
            min: min,
            step: step,
            max: max,
            // autocomplete: autocomplete
          },
          style: styles,
        }}
        name={name}
        minLength="8"
        variant={variant}
        // defaultValue={defaultValue}
        autoComplete={"off"}
        onMouseUp={onMouseUp}
        // defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        type={type}
        className={className}
        id={id}
        label={label}
        style={{ margin: margin, width: width }}
        placeholder={placeholder}
        helperText={helperText}
        fullWidth
        margin="normal"
        multiline={multiline}
        InputLabelProps={{
          shrink: true,
          style: { width: widthLabel },
        }}
        required={required}
        disabled={disabled}
        min="0"
        aria-describedby={describedby}
      />
    )}
  </>
);

export const InputSelectMultiple = ({
  placeholder,
  label,
  helperText,
  className,
  type,
  onChange,
  value,
  required,
  variant,
  options,
  name,
  size,
  error = false,
  optionDisabled,
  disableClearable = true,
  noOptionsText = "No Options",
  disabled = false,
  width = 300,
  id,
  renderOption,
  defaultValue,
  ref,
  margin = 8,
  filterOptions,
}) => (
  <Autocomplete
    multiple
    size={size}
    disableClearable={disableClearable}
    disabled={disabled}
    id={id}
    options={options}
    getOptionLabel={(option) => (option.title ? option.title : "")}
    getOptionSelected={(option, value) => {
      //nothing that is put in here will cause the warning to go away
      if (value?.id === option?.id) {
        return true;
      }
    }}
    renderOption={renderOption}
    getOptionDisabled={optionDisabled}
    onChange={onChange}
    style={{ width: width }}
    defaultValue={defaultValue}
    value={value}
    noOptionsText={noOptionsText}
    renderInput={(params) => (
      <>
        {error ? (
          <CssTextFieldError
            {...params}
            value={value}
            ref={ref}
            type={type}
            className={className}
            id="standard-error"
            label={label}
            style={{ margin: margin }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
          />
        ) : (
          <CssTextFieldBold
            {...params}
            value={value}
            ref={ref}
            type={type}
            className={className}
            id="standard-full-width"
            label={label}
            style={{ margin: margin }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
          />
        )}
      </>
    )}
    filterOptions={filterOptions}
  />
);

//--Input Select autocomplate--
export const InputSelect = ({
  placeholder,
  label,
  helperText,
  className,
  type,
  onChange,
  value,
  required,
  variant,
  options,
  name,
  size,
  error = false,
  optionDisabled,
  disableClearable = true,
  noOptionsText = "No Options",
  disabled = false,
  width = 300,
  id,
  renderOption,
  defaultValue,
  ref,
  margin = 8,
  filterOptions,
}) => (
  <Autocomplete
    size={size}
    disableClearable={disableClearable}
    disabled={disabled}
    id={id}
    options={options}
    getOptionLabel={(option) => (option.title ? option.title : "")}
    getOptionSelected={(option, value) => {
      //nothing that is put in here will cause the warning to go away
      if (value?.id === option?.id) {
        return true;
      }
    }}
    renderOption={renderOption}
    getOptionDisabled={optionDisabled}
    onChange={onChange}
    style={{ width: width }}
    defaultValue={defaultValue}
    value={value}
    noOptionsText={noOptionsText}
    renderInput={(params) => (
      <>
        {error ? (
          <CssTextFieldError
            {...params}
            value={value}
            ref={ref}
            type={type}
            className={className}
            id="standard-error"
            label={label}
            style={{ margin: margin }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
          />
        ) : (
          <CssTextFieldBold
            {...params}
            value={value}
            ref={ref}
            type={type}
            className={className}
            id="standard-full-width"
            label={label}
            style={{ margin: margin }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
          />
        )}
      </>
    )}
    filterOptions={filterOptions}
  />
);

export const InputSelectSearchAsyncCreateable = ({
  placeholder,
  label,
  helperText,
  className,
  type,
  onChange,
  value,
  required,
  variant,
  options,
  name,
  size,
  disabled,
  optionDisabled,
  error = false,
  disableClearable = true,
  onChangeInput,
  valueInput,
  loadingInput,
  noOptionsText = "No Options",
  filterOptions,
  width
}) => (
  <Autocomplete
    size={size}
    disableClearable={disableClearable}
    id="clear-on-escape"
    options={options}
    disabled={disabled}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === "string") {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.title;
    }}
    renderOption={(option) => option.title}
    freeSolo
    getOptionDisabled={optionDisabled}
    onChange={onChange}
    filterOptions={filterOptions}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    style={{ width: width }}
    value={value}
    noOptionsText={noOptionsText}
    renderInput={(params) => (
      <>
        {error ? (
          <CssTextFieldError
            {...params}
            value={valueInput}
            type={type}
            className={className}
            id="standard-error"
            label={label}
            style={{ margin: 8 }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
            onChange={onChangeInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingInput ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        ) : (
          <CssTextFieldBold
            {...params}
            value={valueInput}
            type={type}
            className={className}
            id="standard-full-width"
            label={label}
            style={{ margin: 8 }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
            onChange={onChangeInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingInput ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      </>
    )}
  />
);

export const InputSelectSearchAsync = ({
  placeholder,
  label,
  helperText,
  className,
  type,
  disabled = false,
  onChange,
  value,
  required,
  variant,
  options,
  name,
  size,
  optionDisabled,
  error = false,
  disableClearable = true,
  onChangeInput,
  valueInput,
  loadingInput,
  noOptionsText = "No Options",
  renderOption,
  width = 250,
}) => (
  <Autocomplete
    size={size}
    disableClearable={disableClearable}
    id="clear-on-escape"
    options={options}
    disabled={disabled}
    getOptionLabel={(option) => option.title}
    getOptionDisabled={optionDisabled}
    onChange={onChange}
    style={{ width: width }}
    value={value}
    noOptionsText={noOptionsText}
    renderOption={renderOption}
    renderInput={(params) => (
      <>
        {error ? (
          <CssTextFieldError
            {...params}
            value={valueInput}
            type={type}
            className={className}
            id="standard-error"
            label={label}
            style={{ margin: 8 }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
            onChange={onChangeInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingInput ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        ) : (
          <CssTextFieldBold
            {...params}
            value={valueInput}
            type={type}
            className={className}
            id="standard-full-width"
            label={label}
            style={{ margin: 8 }}
            placeholder={placeholder}
            helperText={helperText}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required={required}
            variant={variant}
            name={name}
            onChange={onChangeInput}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingInput ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      </>
    )}
  />
);

//--Input Currency--
export const InputCurrency = ({ label, required, value, onChange, width }) => (
  <>
    <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
      {label}
      {required ? <span className={"error"}>*</span> : null}
    </Typography>
    <CurrencyFormat
      prefix={"Rp"}
      customInput={CssTextFieldBold}
      value={value}
      thousandSeparator={true}
      onValueChange={onChange}
      style={{ width: width }}
    />
  </>
);

export const InputCurrencyNew = ({
  label,
  type,
  value,
  onChange,
  onKeyUp,
  name,
  variant = "outlined",
  output = "string",
  width,
  required,
  labelBold,
  textBold,
  withoutPrefix,
  withoutLabel,
  disabled,
  minimumValue,
  digitGroupSeparator = ",",
  decimalCharacter = ".",
}) => {
  return (
    <Box>
      {withoutLabel ? null : (
        <>
          {" "}
          {textBold ? (
            <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
              {labelBold}
              {required ? <span className={"error"}>*</span> : null}
            </Typography>
          ) : null}{" "}
        </>
      )}

      <CurrencyTextField
        variant={variant}
        label={label}
        value={value}
        currencySymbol={withoutPrefix ? "" : "Rp"}
        outputFormat={output}
        onChange={onChange}
        onKeyUp={onKeyUp}
        leadingZero="deny"
        decimalPlaces={0}
        decimalPlacesShownOnBlur={0}
        decimalPlacesShownOnFocus={0}
        decimalCharacter={decimalCharacter}
        digitGroupSeparator={digitGroupSeparator}
        style={{ width: width }}
        required={required}
        disabled={disabled}
        minimumValue={minimumValue}
      />
    </Box>
  );
};

//--Input Radio HRIS--
export const OrangeRadio = withStyles({
  root: {
    color: "#D2D2D2",
    "&$checked": {
      color: "#203354",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const GreenCheckbox = withStyles({
  root: {
    // color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

//--Input outlined --
export const InputTextOutlined = ({
  label,
  type,
  value,
  onChange,
  name,
  disabled,
}) => (
  <TextField
    size="small"
    id="outlined-basic"
    label={label}
    variant="outlined"
    type={type}
    onChange={onChange}
    value={value}
    name={name}
    disabled={disabled}
  />
);

// --Input Date--
export const InputDate = ({
  value,
  onChange,
  format,
  label,
  required,
  disabled,
  InputLabelProps,
  inputProps,
  inputValue,
  views,
  width,
  ref,
  style,
  margin = "normal"
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      ref={ref}
      views={views}
      inputValue={inputValue}
      disabled={disabled}
      autoOk
      inputProps={inputProps}
      style={style ? style : { width: width }}
      // disableToolbar
      variant="inline"
      format={format}
      margin={margin}
      id="date-picker-inline"
      // inputProps={{ style: { fontSize: "15px" } }}
      label={label}
      value={value}
      onChange={onChange}
      // InputAdornmentProps={{ position: "start" }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      required={required}
      TextFieldComponent={CssTextFieldBold}
      InputLabelProps={InputLabelProps}
    />
  </MuiPickersUtilsProvider>
);

// --Input Year--
export const InputYear = ({
  value,
  onChange,
  format,
  label,
  required,
  minDate,
  maxDate,
  className,
  disabled,
}) => (
  <ThemeProvider theme={materialTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        className={className}
        views={["year"]}
        value={value}
        onChange={onChange}
        TextFieldComponent={CssTextFieldBold}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        disabled={disabled}
        InputLabelProps={{
          style: { width: "300px" },
        }}
        required={required}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

// --Input Month--
export const InputMonth = ({
  value,
  onChange,
  format,
  label,
  required,
  maxDate,
  className,
  disabled,
}) => (
  <ThemeProvider theme={materialTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={idLocale}>
      <DatePicker
        className={className}
        views={["month"]}
        format="MMMM"
        value={value}
        onChange={onChange}
        TextFieldComponent={CssTextFieldBold}
        disabled={disabled}
        InputLabelProps={{
          style: { width: "300px" },
        }}
        label={label}
        required={required}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

// --Input Time--
export const InputTime = ({
  value,
  onChange,
  format,
  label,
  required,
  ampm,
}) => (
  <KeyboardTimePicker
    format={format}
    margin="normal"
    id="time-picker"
    label={label}
    value={value}
    onChange={onChange}
    ampm={ampm}
    KeyboardButtonProps={{
      "aria-label": "change time",
    }}
    required={required}
    TextFieldComponent={CssTextFieldBold}
  />
);
