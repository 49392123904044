import React, { createContext, useEffect, useState } from 'react';

export const AccessContext = createContext();
const { Provider } = AccessContext;

export const AccessProvider = (props) => {

  const [access, setAccess] = useState([])
  const [isFullAccess, setIsFullAccess] = useState(false)

  // useEffect(() => {
  //   const currentMenuAccess = localStorage.getItem('currentMenuAccess') !== null ? JSON.parse(localStorage.getItem('currentMenuAccess')) : []

  //   setAccess(currentMenuAccess)
  //   setIsFullAccess(currentMenuAccess.indexOf('FULL_ACCESS') > -1)
  // }, [])
  
  const handleChange = (access, isFullAccess) => {
    setAccess(access);
    setIsFullAccess(isFullAccess);
  }
  
  // useEffect(() => {
  //   localStorage.setItem(
  //     "currentMenuAccess",
  //     JSON.stringify(access)
  //   );
  // }, [access])
return(

   <Provider value={[access, isFullAccess, handleChange]}>

      {props.children}

   </Provider>

 );

}