import React from 'react'
//external
//halaman pelamar
const HalamanPelamar = React.lazy(() =>
  import('../Modules/External/HalamanPelamar/HalamanPelamar')
)

//form pelamar
const FormPelamar = React.lazy(() =>
  import('../Modules/External/HalamanPelamar/FormPelamar')
)

//validasi akun
const ValidasiAKun = React.lazy(() =>
  import('../Modules/External/ValidasiKaryawanBaru/ValidasiAkun')
)

const UbahKataSandi = React.lazy(() =>
  import('../Modules/External/ValidasiKaryawanBaru/UbahKataSandi')
)

const UploadDokumen = React.lazy(() =>
  import('../Modules/External/UploadDokumen/UploadDokumen')
)

const UploadDokumenKaryawanMagang = React.lazy(() =>
  import('../Modules/External/UploadDokumen/UploadDokumenKaryawanMagang')
)

const nonauthroutes = [
  {
    path: '/halaman-pelamar/:id',
    component: HalamanPelamar,
    exact: true,
    name: 'HalamanPelamar'
  },
  {
    path: '/form-pelamar/:id',
    component: FormPelamar,
    exact: true,
    name: 'FormPelamar'
  },
  {
    path: '/validasi-akun',
    component: ValidasiAKun,
    exact: true,
    name: 'ValidasiAKun'
  },
  {
    path: '/validasi-akun/ubah-kata-sandi',
    component: UbahKataSandi,
    exact: true,
    name: 'UbahKataSandi'
  },
  {
    path: '/upload-dokumen/:karyawan',
    component: UploadDokumen,
    exact: true,
    name: 'UploadDokumenKaryawanMagang'
  },
  {
    path: '/upload',
    component: UploadDokumenKaryawanMagang,
    exact: true,
    name: 'UploadDokumenKaryawanMagang'
  }
]

export default nonauthroutes
