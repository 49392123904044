import React, { Suspense, useContext } from "react";
import "./App.css";
import { Redirect, Route, Switch } from "react-router-dom";
// import "./Assets/css/Clearsansfonts.css";
import routes from "./Routes/index";
import nonauthroutes from "./Routes/nonauth";
import { Box, LinearProgress } from "@material-ui/core";
import { AuthContext } from "./Context/authContext";
import Login from "./Modules/Login/Login";
import { LoadingContext } from "./Context/loadingContext";
import { DrawerMobileProvider } from "./Context/DrawerMobileContext";
import NotFound404 from "./Components/NotFound404";

function PrivateRoute({ children, ...rest }) {
  if (!localStorage.getItem("access_token")) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest} render={children} />;
}

function App() {
  const [auth, setAuth] = useContext(AuthContext);
  const [loadingGlobal] = useContext(LoadingContext);

  React.useEffect(() => {
    console.log(
      "%c HRIS version ",
      "background: #203354; color: #fff",
      process.env.REACT_APP_HRIS_VERSION
    );
    if (auth === "Token is Expired") {
      localStorage.clear();
      setAuth(null);
    }
  });

  // const [colorContext, setColorContext] = useContext(ColorContext);
  //prohukum.com/practice/...
  //prohukum.com/identity/...
  const simpleRoute = routes.map((route) => ({
    name: route.name,
    path: route.path,
  }));
  const simpleNonAuthRoute = nonauthroutes.map((route) => ({
    name: route.name,
    path: route.path,
  }));

  return (
    <DrawerMobileProvider>
      <Box>
        {loadingGlobal ? (
          <LinearProgress
            style={{ zIndex: "99999", position: "sticky", top: "0" }}
          />
        ) : null}

        <Suspense fallback={<LinearProgress />}>
          <Switch>
            {nonauthroutes.map((route, i) => (
              <Route
                path={route.path}
                exact={route.exact}
                routes={simpleNonAuthRoute}
                component={route.component}
                key={i}
              />
            ))}
            {routes.map((route, i) => (
              <PrivateRoute
                path={route.path}
                exact={route.exact}
                component={route.component}
                routes={simpleRoute}
                key={i}
              />
            ))}
            <Route path="/" render={() => <Redirect to="/beranda" />} exact />
            <Route path="/login" component={Login} exact />
            <Route
              render={() => (
                <>
                  {/* <div className="inner">
                    <div>
                      <h1>Error 404</h1>
                      <p>
                        The page you are looking for does not seem to exist.
                      </p>
                    </div>
                  </div> */}
                  <NotFound404 />
                </>
              )}
              exact
            />
          </Switch>
        </Suspense>
      </Box>
    </DrawerMobileProvider>
  );
}

export default App;
