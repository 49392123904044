import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './Context/authContext'
import { BrowserRouter } from 'react-router-dom'
import './Assets/css/Clearsansfonts.css'
import { LoadingProvider } from './Context/loadingContext'
import { PengaturanProvider } from './Context/PengaturanContext'
import { TabsProvider } from './Context/TabsContext'
import { AccessProvider } from './Context/AccessContext'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <React.StrictMode>
    <LoadingProvider>
      <TabsProvider>
        <AccessProvider>
          <AuthProvider>
            <PengaturanProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </PengaturanProvider>
          </AuthProvider>
        </AccessProvider>
      </TabsProvider>
    </LoadingProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorkerRegistration.unregister()
